import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ConnectBlockProps } from './model';
import './ConnectBlock.scss';

const ConnectBlock: FC<ConnectBlockProps> = ({
  title,
  links,
  socialRedirectionLinks,
  handleOpenRedirectionModal,
}) => (
  <div className="nf-footer-connect">
    {title?.trim().length ? (
      <div className="nf-footer-connect__title">
        <p>{title}</p>
      </div>
    ) : null}
    <span>
      {links?.length
        ? links.map(({ mediaLink, mediaLinkIcon, color, activeColor, mediaTitle }) => {
            const socialRedirectionLink = socialRedirectionLinks?.find((item) => {
              return mediaTitle && item.mediaTitle === mediaTitle;
            });

            if (socialRedirectionLink && handleOpenRedirectionModal) {
              return (
                <button
                  key={mediaTitle}
                  type="button"
                  aria-label={mediaTitle}
                  title={mediaTitle}
                  className={`${color}-default-text ${activeColor}-active-text`}
                  onClick={handleOpenRedirectionModal(socialRedirectionLink.mediaLink)}
                >
                  <DangerouslySetInnerHtml html={mediaLinkIcon?.svg?.content} />
                </button>
              );
            }

            return (
              <a
                aria-label={mediaTitle}
                target="_blank"
                rel="noreferrer noopener"
                href={mediaLink}
                title={mediaTitle}
                key={mediaLink}
                className={`${color}-default-text ${activeColor}-active-text`}
                dangerouslySetInnerHTML={{ __html: mediaLinkIcon?.svg?.content }}
              />
            );
          })
        : null}
    </span>
  </div>
);

export const query = graphql`
  fragment FragmentConnectBlock on TFooterMediaLink {
    properties {
      mediaLink
      mediaLinkIcon {
        fallbackUrl
        svg {
          content
        }
      }
      linkColor {
        ...FragmentColorProps
      }
      activeLinkColor {
        ...FragmentColorProps
      }
      mediaTitle
    }
  }
`;

export default ConnectBlock;
